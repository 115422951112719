import React, { useContext, useEffect, useState } from 'react'
import { GoogleGenerativeAI } from '@google/generative-ai'
import { Button } from '../Button/Button'
import { Alert } from '../Alert/Alert'
import PropTypes from 'prop-types'
import RecordContext from '../../contexts/RecordContext'
import { Save, RefreshCw, Send } from 'lucide-react'
import { AI_MODEL, AI_MODEL_API_KEY } from '../../config'
import { SEARCH_URL, SEARCH_TIMEOUT } from '../settings/globals'
import { useSafeAsync } from '../../helper-functions/query-functions'
import useHttp from '../../hooks/use-http'
import UserContext from '../../contexts/UserContext'

const AIExtracted = ({ s3ImageUrl, digitalObjectInfo }) => {
  const [historyMessages, setHistoryMessages] = useState([])
  const [messages, setMessages] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [lastResponse, setLastResponse] = useState('')
  const record = useContext(RecordContext)
  const genAI = new GoogleGenerativeAI(`${AI_MODEL_API_KEY}`)
  const model = genAI.getGenerativeModel({ model: `${AI_MODEL}` })
  const safeAsync = useSafeAsync()
  const [isInitialized, setIsInitialized] = useState(false)
  const [initializing, setInitializing] = useState(false)
  const { loggedInUser } = useContext(UserContext)
  const [isLargeImage, setIsLargeImage] = useState(false)
  const [processingLargeImage, setProcessingLargeImage] = useState(false)
  const PROCESSING_TIMEOUT = 20000

  useEffect(() => {
    if (!s3ImageUrl || isInitialized) return

    const initializeChat = async () => {
      clearHistory()
      setInitializing(true)
      let processingStartTime

      try {
        const response = await fetch(`${SEARCH_URL}/fetch-image`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ s3Url: s3ImageUrl }),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const imageData = await response.json()
        processingStartTime = Date.now()

        if (imageData.size > 20 * 1024 * 1024) {
          // Check if the data field contains an API URL
          if (
            !imageData.data.startsWith(
              'https://generativelanguage.googleapis.com'
            )
          ) {
            setIsLargeImage(true)
            setMessages([
              {
                text: 'This image is larger than 20MB. Please check back in 2-3 minutes while we process your request. You can refresh the page to see if the transcription is ready.',
                sender: 'system',
              },
            ])
            setIsInitialized(true)
            setInitializing(false)
            return
          }

          // If it's an API URL, proceed with processing but show waiting message
          setProcessingLargeImage(true)
          setMessages([
            {
              text: 'Processing large image (>20MB). This may take a few moments...',
              sender: 'system',
            },
          ])
        }

        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error('Processing timeout')),
            PROCESSING_TIMEOUT
          )
        )

        try {
          const initialResponse = await Promise.race([
            processImageQuery(
              `Describe about the digital object using this JSON schema:
              digitalObject = {'digitalObjectText': string}
              Return: Array<digitalObject>`,
              s3ImageUrl,
              model
            ),
            timeoutPromise,
          ])

          setMessages([{ text: initialResponse, sender: 'ai' }])
          setLastResponse(initialResponse)
          setIsInitialized(true)
          setProcessingLargeImage(false)
        } catch (timeoutError) {
          if (imageData.size > 20 * 1024 * 1024) {
            setMessages([
              {
                text: 'This image is taking longer than expected to process. Please wait while we continue processing...',
                sender: 'system',
              },
            ])

            // Continue processing in background
            processImageQuery(
              `Describe about the digital object using this JSON schema:
              digitalObject = {'digitalObjectText': string}
              Return: Array<digitalObject>`,
              s3ImageUrl,
              model
            )
              .then((response) => {
                setMessages([{ text: response, sender: 'ai' }])
                setLastResponse(response)
                setIsInitialized(true)
                setProcessingLargeImage(false)
              })
              .catch((error) => {
                setError(error.message)
                setProcessingLargeImage(false)
              })
          }
        }
      } catch (error) {
        setError(error.message)
        setProcessingLargeImage(false)
      } finally {
        setInitializing(false)
      }
    }

    initializeChat()
  }, [s3ImageUrl, isInitialized])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!inputValue.trim()) return

    setMessages((prev) => [...prev, { text: inputValue, sender: 'user' }])
    setLoading(true)
    setError(null)

    try {
      const response = await processImageQuery(inputValue, s3ImageUrl, model)
      setMessages((prev) => [...prev, { text: response, sender: 'ai' }])
      setLastResponse(response)
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
      setInputValue('')
    }
  }

  const processImageQuery = (query, s3Url, model) => {
    return safeAsync(
      fetch(`${SEARCH_URL}/fetch-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ s3Url }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
          }
          return response.json()
        })
        .then(async (imageData) => {
          let responseText = ''

          if (imageData.size > 20 * 1024 * 1024) {
            const result = await model.generateContent([
              query,
              {
                fileData: {
                  fileUri: imageData.data,
                  mimeType: imageData.mimeType,
                },
              },
            ])
            responseText = result.response.text()
          } else {
            const aiResponse = await model.generateContent([
              query,
              {
                inlineData: {
                  data: imageData.data,
                  mimeType: imageData.mimeType || 'image/jpeg',
                },
              },
            ])
            responseText = aiResponse.response.text()
          }

          if (!responseText.includes('```json')) {
            setHistoryMessages((prev) => [
              ...prev,
              { prompt: query, response: responseText },
            ])
            return responseText
          }

          try {
            const sanityJson = responseText
              .replace('```json', '`')
              .replace('```', '`')
              .replaceAll('`', '')
            const jsonArray = JSON.parse(sanityJson)

            const formattedjson =
              jsonArray && Array.isArray(jsonArray.digitalObjects)
                ? jsonArray.digitalObjects
                    .map((obj) => obj.digitalObjectText || '')
                    .filter(Boolean)
                    .join('\n\n')
                : jsonArray
                    .map((entry) => entry.digitalObjectText || '')
                    .filter(Boolean)
                    .join('\n\n')

            setHistoryMessages((prev) => [
              ...prev,
              { prompt: query, response: formattedjson },
            ])
            return formattedjson
          } catch (jsonError) {
            setHistoryMessages((prev) => [
              ...prev,
              { prompt: query, response: responseText },
            ])
            return responseText
          }
        })
    )
  }

  const LoadingSpinner = () => (
    <div className="flex items-center justify-center p-4 w-full">
      <div className="relative w-12 h-12">
        <div className="absolute top-0 left-0 w-full h-full">
          <div
            className="w-3 h-3 bg-blue-500 rounded-full absolute animate-bounce"
            style={{
              animation: 'bounce 1s infinite',
              left: '50%',
              transform: 'translateX(-50%)',
              animationDelay: '0s',
            }}
          />
          <div
            className="w-3 h-3 bg-blue-400 rounded-full absolute animate-bounce"
            style={{
              animation: 'bounce 1s infinite',
              left: '25%',
              transform: 'translateX(-50%)',
              animationDelay: '0.2s',
            }}
          />
          <div
            className="w-3 h-3 bg-blue-300 rounded-full absolute animate-bounce"
            style={{
              animation: 'bounce 1s infinite',
              left: '75%',
              transform: 'translateX(-50%)',
              animationDelay: '0.4s',
            }}
          />
        </div>
        <div className="mt-12 text-center text-sm text-gray-500">
          Processing...
        </div>
      </div>
    </div>
  )

  const handleSaveLatest = async (e) => {
    e.preventDefault()
    try {
      const responseHistory = await fetch(
        `${SEARCH_URL}/contributions/targetObjectId/${record?.objectId}?status=active&sort=desc`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      const responseData = await responseHistory.json()
      const parentContributionId =
        responseData[responseData.length - 1]?.parentContributionId || ''

      const response = await fetch(
        `${SEARCH_URL}/transcriptions?publish=true`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            transcription: lastResponse,
            targetNaId: parseInt(record?.naId),
            targetObjectId: record?.objectId,
            userId: loggedInUser?.userId,
            status: 'active',
            parentContributionId: parentContributionId,
          }),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`)
      }

      setMessages((prev) => [
        ...prev,
        { text: 'Latest response saved successfully!', sender: 'system' },
      ])
    } catch (err) {
      setMessages((prev) => [
        ...prev,
        {
          text: `Error saving summary: ${error.message}`,
          sender: 'system',
        },
      ])
    }
  }

  const handleResummarize = async () => {
    try {
      setLoading(true)
      const uniqueHistory = Array.from(
        new Set(
          historyMessages.map((entry) => `${entry.prompt}||${entry.response}`)
        )
      ).map((combined) => {
        const [prompt, response] = combined.split('||')
        return { prompt, response }
      })

      const formattedHistory = uniqueHistory
        .map((entry) => `Question: ${entry.prompt}\nAnswer: ${entry.response}`)
        .join('\n\n')

      const summaryPrompt = `Summarize this chat about a digital object, provide a clear and comprehensive consolidated summary in json format only based on all the conversation. Here's the conversation:\n\n${formattedHistory}`

      const summaryResponse = await model.generateContent(summaryPrompt)
      const consolidatedSummary = summaryResponse.response.text()

      let formattedsummary = ''
      if (consolidatedSummary.includes('```json')) {
        let sanitySummary = consolidatedSummary
          .replace('```json', '`')
          .replace('```', '`')
          .replaceAll('`', '')
        const jsonArraySummary = JSON.parse(sanitySummary)

        formattedsummary =
          jsonArraySummary && Array.isArray(jsonArraySummary.digitalObjects)
            ? jsonArraySummary.digitalObjects
                .map((obj) => obj.digitalObjectText || '')
                .filter(Boolean)
                .join('\n\n')
            : jsonArraySummary
                .map((entry) => entry.digitalObjectText || '')
                .filter(Boolean)
                .join('\n\n')
      }

      const finalSummary = formattedsummary || consolidatedSummary
      setLastResponse(finalSummary)
      setMessages((prev) => [
        ...prev,
        {
          text: finalSummary,
          sender: 'ai',
        },
      ])
    } catch (error) {
      setError(`Error generating summary: ${error.message}`)
    } finally {
      setLoading(false)
    }
  }

  const clearHistory = () => {
    while (historyMessages.length > 0) {
      historyMessages.pop()
    }
  }

  return (
    <div
      className="ai-chat-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth: '400px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        overflow: 'hidden',
        backgroundColor: '#f9f9f9',
      }}
    >
      <div
        className="chat-panel"
        style={{
          flex: 1,
          padding: '16px',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
              maxWidth: '100%',
            }}
          >
            <div
              style={{
                wordBreak: 'break-word',
                padding: '10px 12px',
                borderRadius: '12px',
                backgroundColor:
                  message.sender === 'user' ? '#e0f7fa' : '#ffffff',
                border: '1px solid #ddd',
                textAlign: 'left',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              {message.text}
            </div>
            {message.sender === 'ai' && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: '8px',
                  marginTop: '6px',
                }}
              >
                <Button
                  onClick={handleSaveLatest}
                  disabled={loading}
                  style={{
                    padding: '4px 8px',
                    fontSize: '10px',
                    height: '28px',
                    borderRadius: '4px',
                    border: 'none',
                    backgroundColor: '#f1f1f1',
                    cursor: 'pointer',
                  }}
                >
                  Save
                </Button>
                <Button
                  onClick={handleResummarize}
                  disabled={loading}
                  style={{
                    padding: '4px 8px',
                    fontSize: '10px',
                    height: '28px',
                    borderRadius: '4px',
                    border: 'none',
                    backgroundColor: '#f1f1f1',
                    cursor: 'pointer',
                  }}
                >
                  Summarize
                </Button>
              </div>
            )}
          </div>
        ))}
        {loading && <LoadingSpinner />}
        {processingLargeImage && (
          <div
            className="processing-message"
            style={{
              padding: '12px',
              margin: '10px',
              backgroundColor: '#f0f9ff',
              borderRadius: '8px',
              border: '1px solid #bae6fd',
            }}
          >
            <div className="flex items-center gap-2">
              <RefreshCw className="w-4 h-4 animate-spin" />
              <span>Processing large image... Please wait</span>
            </div>
          </div>
        )}
        {error && <Alert type="error">{error}</Alert>}
      </div>

      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '8px 16px',
          borderTop: '1px solid #ccc',
          backgroundColor: '#ffffff',
        }}
      >
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Ask a question..."
          style={{
            flex: 1,
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '6px',
            fontSize: '14px',
          }}
          aria-label="Chat input"
        />
        <Button
          type="submit"
          disabled={loading || !inputValue.trim()}
          style={{
            padding: '4px 8px',
            fontSize: '10px',
            height: '28px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#f1f1f1',
            cursor: 'pointer',
          }}
        >
          <Send className="w-4 h-4" style={{ color: '#fff' }} />
        </Button>
      </form>
    </div>
  )
}

AIExtracted.propTypes = {
  s3ImageUrl: PropTypes.string.isRequired,
  digitalObjectInfo: PropTypes.array,
}

export default AIExtracted
